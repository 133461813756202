<template>
  <div class="app-container">
    <el-form ref="testPaper" :model="{ testPaper: testPaper }">
      <div class="box" v-for="(item, key) in testPaper" :key="key">
        <div class="box_top">
          <div class="box_top_left">
            <el-select
              v-model="item.topicKey"
              placeholder="请选择题目类型"
              clearable
              :disabled="item.isChange"
              @change="change(item.topicKey, key)"
              style="width: 150px"
              class="filter-item"
            >
              <el-option label="单选题" :value="1" />
              <el-option label="多选题" :value="2" />
              <el-option label="填空题" :value="3" />
              <el-option label="简答题" :value="4" />
              <el-option label="判断题" :value="5" />
            </el-select>
          </div>
        </div>
        <!-- 答题时间 -->
        <el-form-item
          :prop="'testPaper.' + key + '.score'"
          :rules="formDataRules.score"
        >
          <el-input
            style="width: 250px; margin-top: 10px"
            v-model="item.score"
            placeholder="请输入试题答题时间"
          ></el-input>
        </el-form-item>
        <div class="box_cneter" v-if="item.topicKey == 1">
          <div>试题标题</div>
          <el-form-item
            :prop="'testPaper.' + key + '.questions_title'"
            :rules="formDataRules.questions_title"
          >
            <el-input
              v-model="item.questions_title"
              placeholder="请输入内容"
              type="textarea"
              :rows="4"
              style="margin-top: 10px"
            ></el-input>
          </el-form-item>
          <div
            class="box_cneter_select"
            v-for="(i, mukey) in item.multiple"
            :key="i.type"
          >
            {{ i.name }}
            <el-form-item
              :prop="'testPaper.' + key + '.multiple.' + mukey + '.content'"
              :rules="formDataRules.content"
            >
              <el-input
                style="width: 250px; margin-left: 10px; padding-top: 20px"
                v-model="i.content"
                :placeholder="`请输入${i.name}`"
              ></el-input>
            </el-form-item>
            <el-checkbox v-model="i.isanswer" style="margin-left: 10px">
              是否答案
            </el-checkbox>
          </div>
        </div>
        <div class="box_cneter" v-if="item.topicKey == 2">
          <div>试题标题</div>
          <el-form-item
            :prop="'testPaper.' + key + '.questions_title'"
            :rules="formDataRules.questions_title"
          >
            <el-input
              v-model="item.questions_title"
              placeholder="请输入内容"
              type="textarea"
              :rows="4"
              style="margin-top: 10px"
            ></el-input>
          </el-form-item>
          <div style="margin-top: 20px">添加答案</div>
          <el-form-item
            :prop="'testPaper.' + key + '.questions_answer'"
            :rules="formDataRules.questions_answer"
          >
            <el-input
              v-model="item.questions_answer"
              placeholder="请输入内容"
              type="textarea"
              :rows="4"
              style="margin-top: 10px"
            ></el-input>
          </el-form-item>
        </div>
        <div class="box_cneter" v-if="item.topicKey == 3">
          <div>试题标题</div>
          <el-form-item
            :prop="'testPaper.' + key + '.questions_title'"
            :rules="formDataRules.questions_title"
          >
            <el-input
              v-model="item.questions_title"
              placeholder="请输入内容"
              type="textarea"
              :rows="4"
              style=",margin-top: 10px"
            ></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="btn">
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button type="primary" @click="submit" :loading="btnLoading">
        提交
      </el-button>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      semester: "",
      options: [],
      professionals: [],
      value: "",
      input: "",
      score: 0,
      id: null,
      form: {
        id: null,
        name: null,
        class_name: null,
        teacher_name: null,
        duration: null,
        total_score: null,
        content: null,
      },
      testPaper: [
        {
          topicKey: 1,
          isChange: false,
          score: "",
          questions_title: "",
          questions_answer: "",
          multiple: [
            {
              type: 1,
              content: "",
              name: "A选项",
              isanswer: false,
            },
            {
              type: 2,
              content: "",
              name: "B选项",
              isanswer: false,
            },
            {
              type: 3,
              content: "",
              name: "C选项",
              isanswer: false,
            },
            {
              type: 4,
              content: "",
              name: "D选项",
              isanswer: false,
            },
          ],
        },
      ],
      formDataRules: {
        score: [
          { required: true, message: "请填写试题分数", trigger: "change" },
        ],
        questions_title: [
          { required: true, message: "请填写试题标题", trigger: "change" },
        ],
        multiple: [
          {
            type: "array",
            required: true,
            message: "请填写试题分数",
            trigger: "change",
          },
        ],
        content: [
          { required: true, message: "请填写选项内容", trigger: "change" },
        ],
        questions_answer: [
          { required: true, message: "请填写试题答案", trigger: "change" },
        ],
      },
      btnLoading: false,
    };
  },
  created() {
    this.id = this.$route.query.id;
    if (this.id) this.getDetail();
  },
  mounted() {},
  methods: {
    change(e, index) {
      console.log(e, index);
      // this.testPaper[index].isChange=true
    },
    getDetail() {
      request({
        url: "/api/teacherend/teacher/questionBankDetail",
        method: "get",
        params: {
          id: this.id,
        },
      }).then((response) => {
        this.form = response.data;
        this.testPaper = response.data.content;
      });
    },
    submit() {
      this.btnLoading = true;
      this.form.content = this.testPaper;
      request({
        url: "/api/teacherend/teacher/questionBankStore",
        method: "post",
        data: this.form,
      })
        .then((response) => {
          this.btnLoading = false;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$router.go(-1);
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    remove(key) {
      if (this.testPaper.length == 1) {
        return;
      }
      this.testPaper.splice(key, 1);
    },
    next() {
      this.$refs["testPaper"].validate((valid) => {
        if (valid) {
          this.testPaper.push({
            topicKey: 1,
            isChange: false,
            score: "",
            questions_title: "",
            questions_answer: "",
            multiple: [
              {
                type: 1,
                content: "",
                name: "A选项",
                isanswer: false,
              },
              {
                type: 2,
                content: "",
                name: "B选项",
                isanswer: false,
              },
              {
                type: 3,
                content: "",
                name: "C选项",
                isanswer: false,
              },
              {
                type: 4,
                content: "",
                name: "D选项",
                isanswer: false,
              },
            ],
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// .el-form-item {
//     display: flex;
//     align-items: center;
// }
.top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .top_titile {
    width: 110px;
    text-align: left;
    margin-right: 20px;
  }
}
.tips {
  display: flex;
  justify-content: space-between;
  width: 90%;
  text-align: center;
  margin-top: 40px;
}
.box {
  width: 80%;
  border: 1px solid #dcdfe6;
  padding: 20px;
  margin-top: 20px;
  .box_top {
    display: flex;
    justify-content: space-between;
    .box_top_left {
      display: flex;
    }
    // .box_btn{
    //   height: 70px;
    // }
  }
  .box_cneter {
    margin-top: 20px;
  }
  .box_btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
.btn {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
.box_cneter_select {
  display: flex;
  align-items: center;
  // margin-top: 20px;
}
</style>